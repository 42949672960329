import React, { useState } from "react"
import styles from "../../../styles/solutions.module.scss"
import cblockStyles from "../../../styles/commons/cblock.module.scss"
import FsLightbox from "fslightbox-react"
import Layout from "../../../components/layout/layout"
import StartDemo from "../../../components/start-demo/start-demo"
import SEO from "../../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import withAppContext from "../../../contexts/app.context"
import UseCaseOverview from "../../../components/use-case-overview/use-case-overview"
import { Helper } from "../../../utils/helper"
import { ENV_CONSOLE_BASE_URL } from "../../../utils/secrets"
import EiamHeroAnim from "../../../components/animated-graphs/eiam-hero/eiam-hero-anim"
import TopFeatures from "../../../components/top-features/top-features"
import ComparisonTable from "../../../components/comparison-table/comparison-table"
import CascadeVideo from "../../../components/cascade-video/cascade-video"
import PlaySvg from "../../../components/svg/play"
import ArrowSvg from "../../../components/svg/arrow"

const EdgeInfrastructureManagementPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "solutions__edge-infrastructure-management" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const demoLink = "https://player.vimeo.com/video/448790666?autoplay=1"
  const overviewLink = "https://player.vimeo.com/video/409057009?autoplay=1"

  const [isVideoOpened, setVideoOpened] = useState(false)
  const [currentVideoUrl, setCurrentVideoUrl] = useState(overviewLink)

  const openSelectedVideo = () => {
    Helper.triggerGtagVideo(currentVideoUrl)
    setCurrentVideoUrl(overviewLink)
    setVideoOpened(!isVideoOpened)
  }

  const openDemoVideo = () => {
    Helper.triggerGtagVideo(currentVideoUrl)
    setCurrentVideoUrl(demoLink)
    setVideoOpened(!isVideoOpened)
  }

  const [selectedTopFeatIndex, setselectedTopFeatIndex] = useState(1)

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/solutions/edge/edge-infrastructure-and-app-management"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero Block */}
      <section className={styles.header}>
        <div className={styles.header__content}>
          <div className="container">
            <div className="row">
              <div className={`col-lg-7`}>
                <h1 className={styles.header__title}>
                  Edge Infrastructure & App Management
                </h1>
                <p className={styles.header__description}>
                  Bring a cloud operating model, fully SaaS-based, to the
                  infrastructure and app services deployed at your edge
                  locations — no integration or lifecycle management required!
                </p>
                <div className={styles.header__cta}>
                  <a
                    aria-label="Start for Free"
                    href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                    className={"btn btn-primary " + styles.header__btn}>
                    Start for Free
                  </a>
                  <button
                    onClick={openDemoVideo}
                    className={`btn btn-primary ml-4 ${styles.header__btn} ${styles.header__btnPlay}`}>
                    <PlaySvg />
                    Watch Demo
                  </button>
                </div>
                {/* <div className={`pt-4 ${styles.header__doc}`}>
                  <p>
                    <a 
                      aria-label="View demo documentation" 
                      className={styles.header__link} 
                      href="/">
                      View demo documentation
                      <ArrowSvg />
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          <div className={styles.header__imgwrapper}>
            <button
              className={styles.header__imgbtn}
              onClick={openSelectedVideo}>
              <PlaySvg />
            </button>
            <EiamHeroAnim />
          </div>
        </div>
      </section>

      {/* Benefits */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Benefits of Edge Cloud
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <TopFeatures
              props={{
                features: [
                  {
                    title: "Fast transformation at the edge",
                    desc:
                      "Accelerate time-to-market by 12x using our SaaS-based platform. Deploy edge sites in any geography, connect them to your central cloud, and instantly start delivering apps and services.",
                    imgUrl: "/img/solutions/12x-time-to-market.svg",
                    imgAlt: "Quick global web app deployments illustration",
                  },
                  {
                    title: "No infrastructure operations",
                    desc:
                      "VoltStack, along with our global network, provides a turnkey solution for operating a large number of edge sites. DevOps and developers can focus on their apps as infrastructure operations is handled by our SRE team and SaaS-based platform.",
                    imgUrl: "/img/solutions/no-infra-operations.svg",
                    imgAlt: "Automatic sites fleet control illustration",
                  },
                  {
                    title: "Significant cost savings",
                    desc:
                      "Reduce TCO up to 70% through our integrated software stack that combines infrastructure software, K8s-based app management, service mesh, networking, security and centralized observability.",
                    imgUrl: "/img/solutions/significant-cost-savings.svg",
                    imgAlt:
                      "Integrated functions create costs savings illustration",
                  },
                  {
                    title: "Multi-layer edge security",
                    desc:
                      "VoltStack integrates identity, secrets and key management across all edge sites to secure hardware, apps, data and access to cloud resources. VoltMesh provides security and encryption for the edge network and our global network ensures secure and reliable connectivity to cloud resources.",
                    imgUrl: "/img/solutions/maximum-security.svg",
                    imgAlt: "Firewall protecting cloud deployment illustration",
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Comparison Table */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Volterra Edge Cloud vs. Traditional Edge Infra
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <ComparisonTable
              props={{
                cols: [
                  "Features",
                  "Other Edge Solutions",
                  "Volterra Edge-Cloud",
                ],
                rows: [
                  {
                    name: "Consolidated infra, app, security software",
                    cells: ["Included", "Included"],
                  },
                  {
                    name: "App identity, secrets and KMS",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Centralized policy & observability",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Multi-layer network + app security",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Edge-to-cloud connectivity",
                    cells: ["Internet/VPNs", "Global Private Backbone"],
                  },
                  {
                    name: "Lifecycle management",
                    cells: ["DIY", "SaaS"],
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Solution Overview */}
      <UseCaseOverview
        props={{
          content: {
            description: `
            <p>Volterra enables a fully managed “edge cloud” across many edge locations with centralized orchestration, operations, security and observability. Volterra’s platform can be deployed on our industrial-grade edge hardware, your custom/commodity hardware or virtual machines.</p>
            <p>VoltStack provides zero-touch provisioning + automated scaling of edge infrastructure along with centralized app orchestration via K8s APIs. VoltMesh provides secure and high-performance connectivity from edge to cloud. VoltConsole provides a centralized portal and APIs to integrate with your existing CI/CD and SDLC tools.</p>`,
            diagram: {
              alt: "Network & Security diagram",
              name: "edge-infrastructure-management-diagram.png",
              width: 840,
              md: {
                name: "edge-infrastructure-management-diagram-md.png",
                width: 760,
              },
              sm: {
                name: "edge-infrastructure-management-diagram-sm.png",
                width: 460,
              },
            },

            capabilities: {
              left: [
                "Distributed app orchestration",
                "Identity, secrets, and key management",
                "DDoS, WAFS, BOT, IPS and API security",
                "Zero operations of K8s clusters",
                "Distributed service mesh and API GW",
                "Centralized observability and policy",
              ],
            },
          },
          keycapafullwidth: true,
        }}
      />

      {/* Ready in minutes */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__vmtlmr} ${cblockStyles.cblock__grey}`}>
        <div className={"container"}>
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Global Edge Cloud in Minutes
            </h2>
          </div>

          <div className={cblockStyles.cblock__body}>
            <div className={"row " + styles.topfeatsslider}>
              <div className="col-lg-5">
                <div className={styles.topfeatsslider__items}>
                  {/* Item 1 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(1)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 1
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>1</span>
                      <h5>
                        Zero-touch provisioning of edge&nbsp;
                        <span>
                          sites
                          <img
                            className={`ml-2 ${styles.topfeatsslider__caret}`}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Perform zero-touch provisioning and installation of
                        infrastructure + app mgmt software at an edge site using
                        VoltConsole
                      </p>
                      <a
                        href="/docs/quick-start/infrastructure-and-app-management#step-1-provision"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 1: Provision">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 1 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462871342.sd.mp4?s=2d81ac71d92e22af2ca74d30205b688f520033db&profile_id=165",
                                  videoLength: "35000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 2 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(2)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 2
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>2</span>
                      <h5>
                        Create a virtual K8s&nbsp;
                        <span>
                          cluster
                          <img
                            className={styles.topfeatsslider__caret}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Segment fleet and deploy apps on segments of your fleet
                        using our simplified UI, CLI or your existing CI/CD
                        pipeline
                      </p>
                      <a
                        href="/docs/quick-start/infrastructure-and-app-management#step-2-deploy"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 2: Deploy">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 2 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462871052.sd.mp4?s=a8c4038d05a8db427054452271a72655006d2535&profile_id=165",
                                  videoLength: "119000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 3 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(3)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 3
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>3</span>
                      <h5>
                        Securely connect to the&nbsp;
                        <span>
                          cloud
                          <img
                            className={`ml-2 ${styles.topfeatsslider__caret}`}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Connect edge apps in your edge site to your back-end
                        apps running in public / private cloud. Control access
                        to Internet-based SaaS sites
                      </p>
                      <a
                        href="/docs/quick-start/infrastructure-and-app-management#step-3-connect--secure"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 3: Connect & Secure">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 3 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462870761.sd.mp4?s=97b8191ae6f7456ff99292bb1aeebc50f9e74f0e&profile_id=165",
                                  videoLength: "54000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 4 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(4)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 4
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>4</span>
                      <h5>
                        Operate like a&nbsp;
                        <span>
                          fleet
                          <img
                            className={styles.topfeatsslider__caret}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Fleet-wide app or OS upgrades with centralized
                        visibility and control of policies -- across your entire
                        set of edge sites
                      </p>
                      <a
                        href="/docs/quick-start/infrastructure-and-app-management#step-4-operate"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 4: Operate">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 4 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462870379.sd.mp4?s=153ee4b2b379afe31f1b72b0e12555b36227add2&profile_id=165",
                                  videoLength: "59000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className={styles.topfeatsslider__img}>
                  {selectedTopFeatIndex === 1 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462871342.sd.mp4?s=2d81ac71d92e22af2ca74d30205b688f520033db&profile_id=165",
                              videoLength: "35000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 2 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462871052.sd.mp4?s=a8c4038d05a8db427054452271a72655006d2535&profile_id=165",
                              videoLength: "119000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 3 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462870761.sd.mp4?s=97b8191ae6f7456ff99292bb1aeebc50f9e74f0e&profile_id=165",
                              videoLength: "54000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 4 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462870379.sd.mp4?s=153ee4b2b379afe31f1b72b0e12555b36227add2&profile_id=165",
                              videoLength: "59000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <StartDemo
        props={{
          benefits: [
            "Deploy two edge sites for free and upgrade as you scale!",
          ],
          singleline: true,
        }}
      />

      <FsLightbox
        toggler={isVideoOpened}
        sources={[
          <iframe
            title="Video player"
            src={currentVideoUrl}
            width="1920px"
            height="1080px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
        key="1"
      />
    </Layout>
  )
}

export default withAppContext(EdgeInfrastructureManagementPage)
