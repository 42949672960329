import React, { useEffect } from "react"
import styles from "./eiam-hero-anim.module.scss"
import anime from "animejs"

const EiamHeroAnim = () => {
  const totalDuration = 2000
  const inputDuration = 2000
  const innerDuration = 4000
  const slowDuration = 6000
  const awsDuration = 4000
  const internetDuration = 4000

  let maintl
  let input1Tr
  let input2Tr
  let innerTr
  let slowTr
  let awsTr
  let azureTr
  let internetTr

  useEffect(() => {
    maintl = anime.timeline({
      easing: "easeInOutQuad",
      duration: totalDuration,
      autoplay: true,
    })

    input1Tr = anime.timeline({
      easing: "linear",
      duration: inputDuration,
      autoplay: true,
      loop: true,
    })

    input2Tr = anime.timeline({
      easing: "linear",
      duration: inputDuration,
      autoplay: true,
      loop: true,
    })

    innerTr = anime.timeline({
      easing: "linear",
      duration: innerDuration,
      autoplay: true,
      loop: true,
    })

    slowTr = anime.timeline({
      easing: "linear",
      duration: slowDuration,
      autoplay: true,
      loop: true,
    })

    awsTr = anime.timeline({
      easing: "linear",
      duration: awsDuration,
      autoplay: true,
      loop: true,
    })

    azureTr = anime.timeline({
      easing: "linear",
      duration: awsDuration,
      autoplay: true,
      loop: true,
    })

    internetTr = anime.timeline({
      easing: "linear",
      duration: internetDuration,
      autoplay: true,
      loop: true,
    })

    maintl
      .add({
        targets: "#img",
        duration: 900,
        opacity: [0, 1],
        translateY: [-8, 1],
      })
      .add({
        targets: "#svg",
        duration: 900,
        opacity: [0, 1],
      })

    input1Tr
      .add({
        targets: "#bars-input1-1 g",
        duration: inputDuration,
        translateX: [-15.0, 45.0],
        translateY: [-8.0, -50.0],
      })
      .add({
        targets: "#bars-input1-2 g",
        duration: inputDuration,
        translateX: [-84.0, -15.0],
        translateY: [28.0, -8.0],
        delay: -inputDuration,
      })

    input2Tr
      .add({
        targets: "#bars-input2-1 g",
        duration: inputDuration,
        translateX: [-16.54, 53.46],
        translateY: [-10.89, -58.89],
      })
      .add({
        targets: "#bars-input2-2 g",
        duration: inputDuration,
        translateX: [-97.54, -16.54],
        translateY: [31.11, -10.89],
        delay: -inputDuration,
      })

    innerTr
      .add({
        targets: "#bars-main1 > g",
        duration: innerDuration,
        translateX: [-33.0, 5.0],
        translateY: [-125.0, -212.0],
      })
      .add({
        targets: "#bars-main2 > g",
        duration: innerDuration,
        translateX: [-302.0, -142.0],
        translateY: [15.0, -35.0],
        delay: -innerDuration,
      })

    slowTr
      .add({
        targets: "#bars-slow1 g",
        duration: slowDuration,
        translateX: [-91.25, -11.25],
        translateY: [-211.81, -274.81],
      })
      .add({
        targets: "#bars-slow2 g",
        duration: slowDuration,
        translateX: [-553.25, -453.25],
        translateY: [30.19, -10.81],
        delay: -slowDuration,
      })

    awsTr
      .add({
        targets: "#bars-aws1-1 > g",
        duration: awsDuration,
        translateX: [115.22, 237.0],
        translateY: [0.01, -54.0],
      })
      .add({
        targets: "#bars-aws1-2 > g",
        duration: awsDuration,
        translateX: [0.22, 115.2],
        translateY: [58.57, 0.01],
        delay: -awsDuration,
      })

    azureTr
      .add({
        targets: "#bars-azure1 > g",
        duration: awsDuration,
        translateX: [115.15, 245.15],
        translateY: [0.1, -49.9],
      })
      .add({
        targets: "#bars-azure2 > g",
        duration: awsDuration,
        translateX: [0.15, 115.15],
        translateY: [58.67, 0.1],
        delay: -awsDuration,
      })

    internetTr
      .add({
        targets: "#bars-nw1 g",
        duration: internetDuration,
        translateX: [-14.0, 66.0],
        translateY: [-5.57, -64.57],
      })
      .add({
        targets: "#bars-nw2 g",
        duration: internetDuration,
        translateX: [-108.0, -14.0],
        translateY: [43.44, -5.57],
        delay: -internetDuration,
      })
  })

  return (
    <div className={styles.vsanim}>
      <svg
        width="1029px"
        height="592px"
        viewBox="0 0 1029 592"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        id="svg">
        <defs>
          <linearGradient
            x1="50%"
            y1="38.9042307%"
            x2="50%"
            y2="100%"
            id="linearGradient-1">
            <stop
              stopColor="#FFFFFF"
              stopOpacity="0.143575175"
              offset="0%"></stop>
            <stop stopColor="#FFFFFF" offset="100%"></stop>
          </linearGradient>
          <polygon
            id="path-2"
            points="0 50.5237288 6.13395905 54 96.5190692 4.50482427 89.7821029 0.802636467"></polygon>
          <path
            d="M232.513026,3 L238.978714,7.05565036 L209.006295,24.1477566 C207.249826,25.1288428 205.781475,26.5535644 204.747869,28.2796505 C201.538407,33.6393427 203.200323,40.5599991 208.441079,43.8905622 L208.706093,44.0540748 L227.70303,55.4297199 C230.41829,57.0556576 232.671028,59.3509892 234.245827,62.0962247 C239.271397,70.8569425 236.33603,82.0051748 227.707407,87.1710018 L227.404103,87.3487653 L196.483127,105.086526 L189,101.014638 L223.920976,81.2768773 C229.430151,78.1165489 231.334268,71.0885275 228.173939,65.5793521 C227.260272,63.9866208 225.9795,62.6375132 224.440495,61.6429598 L224.106795,61.4353085 L205.109858,50.0596634 C196.344043,44.8105642 193.493181,33.4492311 198.742281,24.6834157 C200.325859,22.0388945 202.543789,19.8341047 205.19231,18.2667531 L205.592792,18.0364558 L232.513026,3 Z"
            id="path-4"></path>
          <path
            d="M157.918413,37.1080536 C173.162925,27.9174482 192.153764,27.6265155 207.65954,36.3098295 L208.153213,36.5900035 L236.186631,52.712034 C249.445138,60.3370065 265.764398,60.2438 278.923352,52.5020061 L279.381093,52.2288412 L296.785245,41.6947201 L304.409871,45.6832162 L283.005719,58.2173373 C267.751066,67.4504423 248.723956,67.7586825 233.1914,59.0607657 L232.696876,58.7801148 L204.663458,42.6580843 C191.429534,35.0472495 175.144141,35.1251088 161.995332,42.8278475 L161.532568,43.1028748 L135.614155,58.5371958 L129,54.5423746 L157.918413,37.1080536 Z"
            id="path-6"></path>
          <path
            d="M0,29.0871261 L18.8032015,39.747171 L50.1197113,22.5030087 C55.2165658,21.2710393 59.6504147,21.4279662 63.4212579,22.9737893 C67.1921012,24.5196125 72.7928946,27.7129746 80.2236383,32.5538758 C90.7983135,38.1329065 100.404709,38.3174389 109.042826,33.107473 C117.680942,27.897507 130.521417,20.3882273 147.56425,10.5796338 L130.276746,0.0665047421 L101.827006,15.5956365 C96.8785999,18.6206202 92.0409372,18.6206202 87.3140177,15.5956365 C82.5870981,12.5706527 76.0689067,8.72439761 67.7594434,4.05687108 C59.4370973,0.690832602 50.7299368,1.29770631 41.6379621,5.87749219 C32.5459874,10.4572781 18.6666667,18.1938227 0,29.0871261 Z"
            id="path-8"></path>
          <path
            d="M0,80.9350918 L18.1483472,90.9350918 L45.7270008,73.9350918 C58.7894475,64.4074148 60.06069,55.5016001 49.5407283,47.2176475 C43.6786473,43.7168179 39.3664809,41.3483415 36.6042292,40.1122185 C27.5188351,35.0921922 25.7713829,30.9070345 31.3618724,27.5567455 C36.952362,24.2064564 45.0809401,18.4552353 55.7476068,10.3030821 L37.4486479,0 C20.5375245,10.8627056 12.0819629,16.2940584 12.0819629,16.2940584 C-1.55961338,26.733132 5.41701758,41.3095652 12.0819629,45.4261978 C23.0611931,52.2075675 27.7171447,54.9491334 26.0498175,53.6508955 C31.3174644,57.9941477 31.3174644,61.6525409 26.0498175,64.626075 C20.7821707,67.5996091 12.0988982,73.035948 0,80.9350918 Z"
            id="path-10"></path>
          <polygon
            id="path-12"
            points="19 45.0538547 82.4405634 12 63.6167401 0 0 34"></polygon>
          <polygon
            id="path-14"
            points="71.7323473 0 92.4644512 13.0500369 22.7168445 51.6619081 5.13280814e-15 39.1121791"></polygon>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd">
          <g id="eiam" transform="translate(-233.000000, -1083.000000)">
            <g id="Group-9-Copy" transform="translate(233.000000, 1637.000000)">
              <g
                id="pattern"
                transform="translate(9.000000, 5.980817)"
                fill="#FFFFFF"
                opacity="0.05">
                <g
                  id="pattern-copy-2"
                  transform="translate(406.000000, 0.000000)">
                  <path
                    d="M145.951333,29.7357736 C146.649179,29.3326186 147.780791,29.3326186 148.479761,29.7357736 C149.177607,30.1389287 149.177607,30.7936345 148.479761,31.1979126 C147.780791,31.6010676 146.649179,31.6010676 145.951333,31.1979126 C145.253487,30.7936345 145.253487,30.1389287 145.951333,29.7357736"
                    id="Fill-54-Copy-11"></path>
                  <path
                    d="M152.271279,26.0816615 C152.969125,25.6785065 154.100737,25.6785065 154.799707,26.0816615 C155.497553,26.4859396 155.497553,27.1395224 154.799707,27.5438005 C154.100737,27.9469556 152.969125,27.9469556 152.271279,27.5438005 C151.573433,27.1395224 151.573433,26.4848166 152.271279,26.0816615"
                    id="Fill-56-Copy-11"></path>
                  <path
                    d="M158.591338,22.4275495 C159.289184,22.0243944 160.420796,22.0243944 161.119766,22.4275495 C161.817612,22.8318275 161.817612,23.4854104 161.119766,23.8896884 C160.420796,24.2928435 159.289184,24.2928435 158.591338,23.8896884 C157.892368,23.4854104 157.892368,22.8318275 158.591338,22.4275495"
                    id="Fill-58-Copy-11"></path>
                  <path
                    d="M164.91016,18.7745604 C165.608006,18.3714053 166.739618,18.3714053 167.438588,18.7745604 C168.136434,19.1777154 168.136434,19.8312983 167.438588,20.2355763 C166.739618,20.6387314 165.608006,20.6387314 164.91016,20.2355763 C164.21119,19.8312983 164.21119,19.1777154 164.91016,18.7745604"
                    id="Fill-60-Copy-11"></path>
                  <path
                    d="M171.230106,15.1204483 C171.927952,14.7172932 173.059564,14.7172932 173.75741,15.1204483 C174.45638,15.5236034 174.45638,16.1771862 173.75741,16.5814643 C173.059564,16.9846193 171.927952,16.9846193 171.230106,16.5814643 C170.531136,16.1771862 170.531136,15.5236034 171.230106,15.1204483"
                    id="Fill-62-Copy-11"></path>
                  <path
                    d="M177.549603,11.4662239 C178.248573,11.0630689 179.379061,11.0630689 180.078031,11.4662239 C180.775877,11.869379 180.775877,12.5229618 180.078031,12.9272399 C179.379061,13.3303949 178.248573,13.3303949 177.549603,12.9272399 C176.851757,12.5229618 176.851757,11.869379 177.549603,11.4662239"
                    id="Fill-64-Copy-11"></path>
                  <path
                    d="M183.868425,7.81211183 C184.567395,7.40895678 185.697883,7.40895678 186.396853,7.81211183 C187.094699,8.21526689 187.094699,8.86884974 186.396853,9.27312779 C185.697883,9.67740584 184.567395,9.67740584 183.868425,9.27312779 C183.170579,8.86884974 183.170579,8.21526689 183.868425,7.81211183"
                    id="Fill-66-Copy-11"></path>
                  <path
                    d="M190.188371,4.15799975 C190.886217,3.7548447 192.017829,3.7548447 192.716799,4.15799975 C193.414645,4.56115481 193.414645,5.21473766 192.716799,5.61901571 C192.017829,6.02329376 190.886217,6.02329376 190.188371,5.61901571 C189.490525,5.21473766 189.490525,4.56115481 190.188371,4.15799975"
                    id="Fill-68-Copy-11"></path>
                  <path
                    d="M196.50843,0.503887671 C197.206276,0.100732614 198.337888,0.100732614 199.036858,0.503887671 C199.734704,0.907042727 199.734704,1.56062558 199.036858,1.96602662 C198.337888,2.36918168 197.206276,2.36918168 196.50843,1.96602662 C195.810584,1.56062558 195.810584,0.907042727 196.50843,0.503887671"
                    id="Fill-70-Copy-11"></path>
                  <path
                    d="M158.591338,29.7357736 C159.289184,29.3326186 160.420796,29.3326186 161.119766,29.7357736 C161.817612,30.1400517 161.817612,30.7936345 161.119766,31.1979126 C160.420796,31.6010676 159.289184,31.6010676 158.591338,31.1979126 C157.893492,30.7936345 157.893492,30.1389287 158.591338,29.7357736"
                    id="Fill-120-Copy-11"></path>
                  <path
                    d="M164.91016,26.0816615 C165.608006,25.6785065 166.739618,25.6785065 167.438588,26.0816615 C168.136434,26.4859396 168.136434,27.1395224 167.438588,27.5438005 C166.739618,27.9469556 165.608006,27.9469556 164.91016,27.5438005 C164.21119,27.1395224 164.21119,26.4859396 164.91016,26.0816615"
                    id="Fill-122-Copy-11"></path>
                  <path
                    d="M171.230106,22.4275495 C171.927952,22.0243944 173.059564,22.0243944 173.75741,22.4275495 C174.45638,22.8318275 174.45638,23.4854104 173.75741,23.8896884 C173.059564,24.2928435 171.927952,24.2928435 171.230106,23.8896884 C170.531136,23.4854104 170.531136,22.8318275 171.230106,22.4275495"
                    id="Fill-124-Copy-11"></path>
                  <path
                    d="M177.549603,18.7745604 C178.248573,18.3714053 179.379061,18.3714053 180.078031,18.7745604 C180.775877,19.1777154 180.775877,19.8312983 180.078031,20.2355763 C179.379061,20.6387314 178.248573,20.6387314 177.549603,20.2355763 C176.851757,19.8312983 176.851757,19.1777154 177.549603,18.7745604"
                    id="Fill-126-Copy-11"></path>
                  <path
                    d="M183.868425,15.1204483 C184.567395,14.7172932 185.697883,14.7172932 186.396853,15.1204483 C187.094699,15.5236034 187.094699,16.1771862 186.396853,16.5814643 C185.697883,16.9846193 184.567395,16.9846193 183.868425,16.5814643 C183.170579,16.1771862 183.170579,15.5236034 183.868425,15.1204483"
                    id="Fill-128-Copy-11"></path>
                  <path
                    d="M190.188371,11.4662239 C190.887341,11.0630689 192.017829,11.0630689 192.716799,11.4662239 C193.414645,11.869379 193.414645,12.5229618 192.716799,12.9272399 C192.017829,13.3303949 190.886217,13.3303949 190.188371,12.9272399 C189.490525,12.5229618 189.490525,11.869379 190.188371,11.4662239"
                    id="Fill-130-Copy-11"></path>
                  <path
                    d="M196.50843,7.81211183 C197.206276,7.40895678 198.337888,7.40895678 199.036858,7.81211183 C199.734704,8.21526689 199.734704,8.86884974 199.036858,9.27312779 C198.337888,9.67628285 197.206276,9.67628285 196.50843,9.27312779 C195.810584,8.86884974 195.810584,8.21526689 196.50843,7.81211183"
                    id="Fill-132-Copy-11"></path>
                  <path
                    d="M202.827252,4.15799975 C203.525098,3.7548447 204.65671,3.7548447 205.35568,4.15799975 C206.053526,4.56115481 206.053526,5.21473766 205.35568,5.61901571 C204.65671,6.02329376 203.525098,6.02329376 202.827252,5.61901571 C202.129406,5.21473766 202.129406,4.56115481 202.827252,4.15799975"
                    id="Fill-134-Copy-11"></path>
                  <path
                    d="M171.230106,29.7357736 C171.927952,29.3326186 173.059564,29.3326186 173.75741,29.7357736 C174.45638,30.1389287 174.45638,30.7936345 173.75741,31.1979126 C173.059564,31.6010676 171.927952,31.6010676 171.230106,31.1979126 C170.531136,30.7936345 170.531136,30.1389287 171.230106,29.7357736"
                    id="Fill-186-Copy-11"></path>
                  <path
                    d="M177.549603,26.0816615 C178.248573,25.6785065 179.379061,25.6785065 180.078031,26.0816615 C180.775877,26.4859396 180.775877,27.1395224 180.078031,27.5438005 C179.379061,27.9469556 178.248573,27.9469556 177.549603,27.5438005 C176.851757,27.1395224 176.851757,26.4859396 177.549603,26.0816615"
                    id="Fill-188-Copy-11"></path>
                  <path
                    d="M183.868425,22.4275495 C184.567395,22.0243944 185.697883,22.0243944 186.396853,22.4275495 C187.094699,22.8318275 187.094699,23.4854104 186.396853,23.8896884 C185.697883,24.2928435 184.567395,24.2928435 183.868425,23.8896884 C183.170579,23.4854104 183.170579,22.8318275 183.868425,22.4275495"
                    id="Fill-190-Copy-11"></path>
                  <path
                    d="M190.188371,18.7745604 C190.886217,18.3714053 192.017829,18.3714053 192.716799,18.7745604 C193.414645,19.1777154 193.414645,19.8312983 192.716799,20.2355763 C192.017829,20.6387314 190.886217,20.6387314 190.188371,20.2355763 C189.490525,19.8312983 189.490525,19.1777154 190.188371,18.7745604"
                    id="Fill-192-Copy-11"></path>
                  <path
                    d="M196.50843,15.1204483 C197.206276,14.7172932 198.337888,14.7172932 199.036858,15.1204483 C199.734704,15.5236034 199.734704,16.1771862 199.036858,16.5814643 C198.337888,16.9846193 197.206276,16.9846193 196.50843,16.5814643 C195.810584,16.1771862 195.810584,15.5236034 196.50843,15.1204483"
                    id="Fill-194-Copy-11"></path>
                  <path
                    d="M202.827252,11.4662239 C203.525098,11.0630689 204.65671,11.0630689 205.35568,11.4662239 C206.053526,11.869379 206.053526,12.5229618 205.35568,12.9272399 C204.65671,13.3303949 203.525098,13.3303949 202.827252,12.9272399 C202.129406,12.5229618 202.129406,11.869379 202.827252,11.4662239"
                    id="Fill-196-Copy-11"></path>
                  <path
                    d="M209.147198,7.81211183 C209.845044,7.40895678 210.976656,7.40895678 211.675626,7.81211183 C212.373472,8.21526689 212.373472,8.86884974 211.675626,9.27312779 C210.976656,9.67628285 209.845044,9.67628285 209.147198,9.27312779 C208.449352,8.86884974 208.449352,8.21526689 209.147198,7.81211183"
                    id="Fill-198-Copy-11"></path>
                  <path
                    d="M183.868425,29.7357736 C184.567395,29.3326186 185.697883,29.3326186 186.396853,29.7357736 C187.094699,30.1389287 187.094699,30.7936345 186.396853,31.1979126 C185.697883,31.6010676 184.567395,31.6010676 183.868425,31.1979126 C183.170579,30.7936345 183.170579,30.1389287 183.868425,29.7357736"
                    id="Fill-252-Copy-11"></path>
                  <path
                    d="M190.188371,26.0816615 C190.886217,25.6785065 192.017829,25.6785065 192.716799,26.0816615 C193.414645,26.4859396 193.414645,27.1395224 192.716799,27.5438005 C192.017829,27.9469556 190.887341,27.9469556 190.188371,27.5438005 C189.490525,27.1395224 189.490525,26.4848166 190.188371,26.0816615"
                    id="Fill-254-Copy-11"></path>
                  <path
                    d="M196.50843,22.4275495 C197.206276,22.0243944 198.337888,22.0243944 199.036858,22.4275495 C199.734704,22.8318275 199.734704,23.4854104 199.036858,23.8896884 C198.337888,24.2928435 197.206276,24.2928435 196.50843,23.8896884 C195.810584,23.4854104 195.810584,22.8318275 196.50843,22.4275495"
                    id="Fill-256-Copy-11"></path>
                  <path
                    d="M202.827252,18.7745604 C203.525098,18.3714053 204.65671,18.3714053 205.35568,18.7745604 C206.053526,19.1777154 206.053526,19.8312983 205.35568,20.2355763 C204.65671,20.6387314 203.525098,20.6387314 202.827252,20.2355763 C202.129406,19.8312983 202.129406,19.1777154 202.827252,18.7745604"
                    id="Fill-258-Copy-11"></path>
                  <path
                    d="M209.147198,15.1204483 C209.845044,14.7172932 210.976656,14.7172932 211.675626,15.1204483 C212.373472,15.5236034 212.373472,16.1771862 211.675626,16.5814643 C210.976656,16.9846193 209.845044,16.9846193 209.147198,16.5814643 C208.449352,16.1771862 208.449352,15.5236034 209.147198,15.1204483"
                    id="Fill-260-Copy-11"></path>
                  <path
                    d="M215.467257,11.4662239 C216.165103,11.0630689 217.296715,11.0630689 217.995685,11.4662239 C218.693531,11.869379 218.693531,12.5229618 217.995685,12.9272399 C217.296715,13.3303949 216.165103,13.3303949 215.467257,12.9272399 C214.769411,12.5229618 214.769411,11.869379 215.467257,11.4662239"
                    id="Fill-262-Copy-11"></path>
                  <path
                    d="M196.50843,29.7357736 C197.206276,29.3326186 198.337888,29.3326186 199.036858,29.7357736 C199.734704,30.1400517 199.734704,30.7936345 199.036858,31.1979126 C198.337888,31.6010676 197.206276,31.6010676 196.50843,31.1979126 C195.810584,30.7936345 195.810584,30.1389287 196.50843,29.7357736"
                    id="Fill-318-Copy-11"></path>
                  <path
                    d="M202.827252,26.0816615 C203.525098,25.6785065 204.65671,25.6785065 205.35568,26.0816615 C206.053526,26.4859396 206.053526,27.1395224 205.35568,27.5438005 C204.65671,27.9469556 203.525098,27.9469556 202.827252,27.5438005 C202.129406,27.1395224 202.129406,26.4859396 202.827252,26.0816615"
                    id="Fill-320-Copy-11"></path>
                  <path
                    d="M209.147198,22.4275495 C209.845044,22.0243944 210.976656,22.0243944 211.675626,22.4275495 C212.373472,22.8318275 212.373472,23.4854104 211.675626,23.8896884 C210.976656,24.2928435 209.845044,24.2928435 209.147198,23.8896884 C208.449352,23.4854104 208.449352,22.8318275 209.147198,22.4275495"
                    id="Fill-322-Copy-11"></path>
                  <path
                    d="M215.467257,18.7745604 C216.165103,18.3714053 217.296715,18.3714053 217.995685,18.7745604 C218.693531,19.1777154 218.693531,19.8312983 217.995685,20.2355763 C217.296715,20.6387314 216.165103,20.6387314 215.467257,20.2355763 C214.769411,19.8312983 214.769411,19.1777154 215.467257,18.7745604"
                    id="Fill-324-Copy-11"></path>
                  <path
                    d="M221.786079,15.1204483 C222.485049,14.7172932 223.615537,14.7172932 224.314507,15.1204483 C225.012353,15.5236034 225.012353,16.1771862 224.314507,16.5814643 C223.615537,16.9846193 222.485049,16.9846193 221.786079,16.5814643 C221.088233,16.1771862 221.088233,15.5236034 221.786079,15.1204483"
                    id="Fill-326-Copy-11"></path>
                  <path
                    d="M209.147198,29.7357736 C209.845044,29.3326186 210.976656,29.3326186 211.675626,29.7357736 C212.373472,30.1389287 212.373472,30.7936345 211.675626,31.1979126 C210.976656,31.6010676 209.845044,31.6010676 209.147198,31.1979126 C208.449352,30.7936345 208.449352,30.1389287 209.147198,29.7357736"
                    id="Fill-384-Copy-11"></path>
                  <path
                    d="M215.467257,26.0816615 C216.165103,25.6785065 217.296715,25.6785065 217.995685,26.0816615 C218.693531,26.4848166 218.693531,27.1395224 217.995685,27.5438005 C217.296715,27.9469556 216.165103,27.9469556 215.467257,27.5438005 C214.769411,27.1395224 214.769411,26.4859396 215.467257,26.0816615"
                    id="Fill-386-Copy-11"></path>
                  <path
                    d="M221.786079,22.4275495 C222.485049,22.0243944 223.615537,22.0243944 224.314507,22.4275495 C225.012353,22.8318275 225.012353,23.4854104 224.314507,23.8896884 C223.615537,24.2928435 222.485049,24.2928435 221.786079,23.8896884 C221.088233,23.4854104 221.088233,22.8318275 221.786079,22.4275495"
                    id="Fill-388-Copy-11"></path>
                  <path
                    d="M228.105463,18.7745604 C228.804433,18.3714053 229.936045,18.3714053 230.633891,18.7745604 C231.331737,19.1777154 231.331737,19.8312983 230.633891,20.2355763 C229.936045,20.6387314 228.804433,20.6387314 228.105463,20.2355763 C227.407617,19.8312983 227.407617,19.1777154 228.105463,18.7745604"
                    id="Fill-390-Copy-11"></path>
                  <path
                    d="M221.786079,29.7357736 C222.485049,29.3326186 223.615537,29.3326186 224.314507,29.7357736 C225.012353,30.1389287 225.012353,30.7936345 224.314507,31.1979126 C223.615537,31.6010676 222.485049,31.6010676 221.786079,31.1979126 C221.088233,30.7936345 221.088233,30.1389287 221.786079,29.7357736"
                    id="Fill-450-Copy-11"></path>
                  <path
                    d="M228.105463,26.0816615 C228.804433,25.6785065 229.936045,25.6785065 230.633891,26.0816615 C231.331737,26.4859396 231.331737,27.1395224 230.633891,27.5438005 C229.936045,27.9469556 228.804433,27.9469556 228.105463,27.5438005 C227.407617,27.1395224 227.407617,26.4859396 228.105463,26.0816615"
                    id="Fill-452-Copy-11"></path>
                  <path
                    d="M234.425522,22.4275495 C235.124492,22.0243944 236.256104,22.0243944 236.95395,22.4275495 C237.651796,22.8318275 237.651796,23.4854104 236.95395,23.8896884 C236.256104,24.2928435 235.124492,24.2928435 234.425522,23.8896884 C233.727676,23.4854104 233.727676,22.8318275 234.425522,22.4275495"
                    id="Fill-454-Copy-11"></path>
                  <path
                    d="M234.425522,29.7357736 C235.124492,29.3326186 236.256104,29.3326186 236.95395,29.7357736 C237.651796,30.1389287 237.651796,30.7936345 236.95395,31.1979126 C236.256104,31.6010676 235.124492,31.6010676 234.425522,31.1979126 C233.727676,30.7936345 233.727676,30.1389287 234.425522,29.7357736"
                    id="Fill-516-Copy-11"></path>
                  <path
                    d="M240.745468,26.0816615 C241.444438,25.6785065 242.57605,25.6785065 243.273896,26.0816615 C243.971742,26.4859396 243.971742,27.1395224 243.273896,27.5438005 C242.57605,27.9469556 241.444438,27.9469556 240.745468,27.5438005 C240.047622,27.1395224 240.047622,26.4859396 240.745468,26.0816615"
                    id="Fill-518-Copy-11"></path>
                  <path
                    d="M247.06429,29.7357736 C247.762136,29.3326186 248.894872,29.3326186 249.592718,29.7357736 C250.290564,30.1389287 250.290564,30.7936345 249.592718,31.1979126 C248.894872,31.6010676 247.762136,31.6010676 247.06429,31.1979126 C246.366444,30.7936345 246.366444,30.1389287 247.06429,29.7357736"
                    id="Fill-582-Copy-11"></path>
                </g>
              </g>
              <path
                d="M613.147825,0.603577707 C619.491914,0.603577707 625.839243,2.00086367 630.680776,4.81569876 L630.680776,4.81569876 L1020.85511,226.980316 C1025.51255,229.688641 1027.91311,233.213934 1027.91311,236.779058 C1027.91311,240.344131 1025.51258,243.868701 1020.85815,246.573982 L1020.85815,246.573982 L433.696554,583.459064 C424.189765,588.988117 417.18968,591.256761 410.31156,590.841736 C402.656246,590.379816 395.1681,586.61404 384.582721,580.457696 L384.582721,580.457696 L7.65947077,363.518899 C3.00339826,360.811916 0.603577707,357.287314 0.603577707,353.722246 C0.603577707,350.157127 3.00342782,346.631802 7.65765977,343.924579 L7.65765977,343.924579 L595.619591,4.81298486 C600.456408,2.00086367 606.803737,0.603577707 613.147825,0.603577707 Z"
                id="--copy"
                stroke="url(#linearGradient-1)"
                strokeWidth="1.20715541"
                opacity="0.300000012"></path>
            </g>
            <g
              id="ztanim-copy"
              opacity="0.5"
              transform="translate(233.000000, 1083.000000)">
              <g id="traffic-nw" transform="translate(537.000000, 196.000000)">
                <mask id="mask-3" fill="white">
                  <use xlinkHref="#path-2"></use>
                </mask>
                <g id="traffic-nw-mask" opacity="0.200000003"></g>
                <g id="bars-nw1" mask="url(#mask-3)" fill="#D8D8D8">
                  <g transform="translate(-14.000000, -5.565000)">
                    <polygon
                      id="Path-15"
                      points="54.770293 20 88.7730664 38.5841829 83.092358 41.9897805 48.2946236 22.932"></polygon>
                    <polygon
                      id="Path-15-Copy"
                      points="45.4756693 24.5841829 79.4784428 43.1683659 73.7977344 46.5739634 39 27.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-2"
                      points="36.4756693 29.5841829 70.4784428 48.1683659 64.7977344 51.5739634 30 32.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-3"
                      points="26.4756693 34.5841829 60.4784428 53.1683659 54.7977344 56.5739634 20 37.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-9"
                      points="91.770293 0.565 125.773066 19.1491829 120.092358 22.5547805 85.2946236 3.497"></polygon>
                    <polygon
                      id="Path-15-Copy-8"
                      points="82.4756693 5.14918295 116.478443 23.7333659 110.797734 27.1389634 76 8.08118295"></polygon>
                    <polygon
                      id="Path-15-Copy-7"
                      points="73.4756693 10.1491829 107.478443 28.7333659 101.797734 32.1389634 67 13.0811829"></polygon>
                    <polygon
                      id="Path-15-Copy-6"
                      points="63.4756693 15.1491829 97.4784428 33.7333659 91.7977344 37.1389634 57 18.0811829"></polygon>
                    <polygon
                      id="Path-15-Copy-4"
                      points="16.4756693 39.5841829 50.4784428 58.1683659 44.7977344 61.5739634 10 42.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-5"
                      points="6.47566933 44.5841829 40.4784428 63.1683659 34.7977344 66.5739634 0 47.5161829"></polygon>
                  </g>
                </g>
                <g id="bars-nw2" mask="url(#mask-3)" fill="#D8D8D8">
                  <g transform="translate(-108.000000, 43.435000)">
                    <polygon
                      id="Path-15"
                      points="54.770293 20 88.7730664 38.5841829 83.092358 41.9897805 48.2946236 22.932"></polygon>
                    <polygon
                      id="Path-15-Copy"
                      points="45.4756693 24.5841829 79.4784428 43.1683659 73.7977344 46.5739634 39 27.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-2"
                      points="36.4756693 29.5841829 70.4784428 48.1683659 64.7977344 51.5739634 30 32.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-3"
                      points="26.4756693 34.5841829 60.4784428 53.1683659 54.7977344 56.5739634 20 37.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-9"
                      points="91.770293 0.565 125.773066 19.1491829 120.092358 22.5547805 85.2946236 3.497"></polygon>
                    <polygon
                      id="Path-15-Copy-8"
                      points="82.4756693 5.14918295 116.478443 23.7333659 110.797734 27.1389634 76 8.08118295"></polygon>
                    <polygon
                      id="Path-15-Copy-7"
                      points="73.4756693 10.1491829 107.478443 28.7333659 101.797734 32.1389634 67 13.0811829"></polygon>
                    <polygon
                      id="Path-15-Copy-6"
                      points="63.4756693 15.1491829 97.4784428 33.7333659 91.7977344 37.1389634 57 18.0811829"></polygon>
                    <polygon
                      id="Path-15-Copy-4"
                      points="16.4756693 39.5841829 50.4784428 58.1683659 44.7977344 61.5739634 10 42.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-5"
                      points="6.47566933 44.5841829 40.4784428 63.1683659 34.7977344 66.5739634 0 47.5161829"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="traffic-aws1"
                transform="translate(328.783368, 135.430235)">
                <mask id="mask-5" fill="white">
                  <use xlinkHref="#path-4"></use>
                </mask>
                <g id="mask-aws1" fillRule="nonzero"></g>
                <g id="bars-aws1-1" mask="url(#mask-5)" fill="#D8D8D8">
                  <g transform="translate(115.216632, 0.004765)">
                    <polygon
                      id="Path-15"
                      points="74.770293 20 159.350779 69.4408 153.670071 72.8463976 68.2946236 22.932"></polygon>
                    <polygon
                      id="Path-15-Copy"
                      points="65.4756693 24.5841829 150.056156 74.0249829 144.375447 77.4305805 59 27.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-2"
                      points="56.4756693 29.5841829 141.056156 79.0249829 135.375447 82.4305805 50 32.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-3"
                      points="46.4756693 34.5841829 131.056156 84.0249829 125.375447 87.4305805 40 37.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-9"
                      points="111.770293 0.565 196.350779 50.0058 190.670071 53.4113976 105.294624 3.497"></polygon>
                    <polygon
                      id="Path-15-Copy-8"
                      points="102.475669 5.14918295 187.056156 54.5899829 181.375447 57.9955805 96 8.08118295"></polygon>
                    <polygon
                      id="Path-15-Copy-7"
                      points="93.4756693 10.1491829 178.056156 59.5899829 172.375447 62.9955805 87 13.0811829"></polygon>
                    <polygon
                      id="Path-15-Copy-6"
                      points="83.4756693 15.1491829 168.056156 64.5899829 162.375447 67.9955805 77 18.0811829"></polygon>
                    <polygon
                      id="Path-15-Copy-4"
                      points="36.4756693 39.5841829 121.056156 89.0249829 115.375447 92.4305805 30 42.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-5"
                      points="26.4756693 44.5841829 111.056156 94.0249829 105.375447 97.4305805 20 47.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-10"
                      points="16.4756693 49.5841829 101.056156 99.0249829 95.3754471 102.43058 10 52.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-11"
                      points="6.47566933 54.5841829 91.0561555 104.024983 85.3754471 107.43058 0 57.5161829"></polygon>
                  </g>
                </g>
                <g id="bars-aws1-2" mask="url(#mask-5)" fill="#D8D8D8">
                  <g transform="translate(0.216632, 58.569765)">
                    <polygon
                      id="Path-15"
                      points="74.770293 20 159.350779 69.4408 153.670071 72.8463976 68.2946236 22.932"></polygon>
                    <polygon
                      id="Path-15-Copy"
                      points="65.4756693 24.5841829 150.056156 74.0249829 144.375447 77.4305805 59 27.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-2"
                      points="56.4756693 29.5841829 141.056156 79.0249829 135.375447 82.4305805 50 32.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-3"
                      points="46.4756693 34.5841829 131.056156 84.0249829 125.375447 87.4305805 40 37.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-9"
                      points="111.770293 0.565 196.350779 50.0058 190.670071 53.4113976 105.294624 3.497"></polygon>
                    <polygon
                      id="Path-15-Copy-8"
                      points="102.475669 5.14918295 187.056156 54.5899829 181.375447 57.9955805 96 8.08118295"></polygon>
                    <polygon
                      id="Path-15-Copy-7"
                      points="93.4756693 10.1491829 178.056156 59.5899829 172.375447 62.9955805 87 13.0811829"></polygon>
                    <polygon
                      id="Path-15-Copy-6"
                      points="83.4756693 15.1491829 168.056156 64.5899829 162.375447 67.9955805 77 18.0811829"></polygon>
                    <polygon
                      id="Path-15-Copy-4"
                      points="36.4756693 39.5841829 121.056156 89.0249829 115.375447 92.4305805 30 42.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-5"
                      points="26.4756693 44.5841829 111.056156 94.0249829 105.375447 97.4305805 20 47.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-10"
                      points="16.4756693 49.5841829 101.056156 99.0249829 95.3754471 102.43058 10 52.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-11"
                      points="6.47566933 54.5841829 91.0561555 104.024983 85.3754471 107.43058 0 57.5161829"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="traffic-azure"
                transform="translate(426.847909, 202.895215)">
                <mask id="mask-7" fill="white">
                  <use xlinkHref="#path-6"></use>
                </mask>
                <g id="mask-azure" fillRule="nonzero"></g>
                <g id="bars-azure1" mask="url(#mask-7)" fill="#D8D8D8">
                  <g transform="translate(115.152091, 0.104785)">
                    <polygon
                      id="Path-15"
                      points="74.770293 20 159.350779 69.4408 153.670071 72.8463976 68.2946236 22.932"></polygon>
                    <polygon
                      id="Path-15-Copy"
                      points="65.4756693 24.5841829 150.056156 74.0249829 144.375447 77.4305805 59 27.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-2"
                      points="56.4756693 29.5841829 141.056156 79.0249829 135.375447 82.4305805 50 32.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-3"
                      points="46.4756693 34.5841829 131.056156 84.0249829 125.375447 87.4305805 40 37.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-9"
                      points="111.770293 0.565 196.350779 50.0058 190.670071 53.4113976 105.294624 3.497"></polygon>
                    <polygon
                      id="Path-15-Copy-8"
                      points="102.475669 5.14918295 187.056156 54.5899829 181.375447 57.9955805 96 8.08118295"></polygon>
                    <polygon
                      id="Path-15-Copy-7"
                      points="93.4756693 10.1491829 178.056156 59.5899829 172.375447 62.9955805 87 13.0811829"></polygon>
                    <polygon
                      id="Path-15-Copy-6"
                      points="83.4756693 15.1491829 168.056156 64.5899829 162.375447 67.9955805 77 18.0811829"></polygon>
                    <polygon
                      id="Path-15-Copy-4"
                      points="36.4756693 39.5841829 121.056156 89.0249829 115.375447 92.4305805 30 42.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-5"
                      points="26.4756693 44.5841829 111.056156 94.0249829 105.375447 97.4305805 20 47.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-10"
                      points="16.4756693 49.5841829 101.056156 99.0249829 95.3754471 102.43058 10 52.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-11"
                      points="6.47566933 54.5841829 91.0561555 104.024983 85.3754471 107.43058 0 57.5161829"></polygon>
                  </g>
                </g>
                <g id="bars-azure2" mask="url(#mask-7)" fill="#D8D8D8">
                  <g transform="translate(0.152091, 58.669785)">
                    <polygon
                      id="Path-15"
                      points="74.770293 20 159.350779 69.4408 153.670071 72.8463976 68.2946236 22.932"></polygon>
                    <polygon
                      id="Path-15-Copy"
                      points="65.4756693 24.5841829 150.056156 74.0249829 144.375447 77.4305805 59 27.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-2"
                      points="56.4756693 29.5841829 141.056156 79.0249829 135.375447 82.4305805 50 32.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-3"
                      points="46.4756693 34.5841829 131.056156 84.0249829 125.375447 87.4305805 40 37.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-9"
                      points="111.770293 0.565 196.350779 50.0058 190.670071 53.4113976 105.294624 3.497"></polygon>
                    <polygon
                      id="Path-15-Copy-8"
                      points="102.475669 5.14918295 187.056156 54.5899829 181.375447 57.9955805 96 8.08118295"></polygon>
                    <polygon
                      id="Path-15-Copy-7"
                      points="93.4756693 10.1491829 178.056156 59.5899829 172.375447 62.9955805 87 13.0811829"></polygon>
                    <polygon
                      id="Path-15-Copy-6"
                      points="83.4756693 15.1491829 168.056156 64.5899829 162.375447 67.9955805 77 18.0811829"></polygon>
                    <polygon
                      id="Path-15-Copy-4"
                      points="36.4756693 39.5841829 121.056156 89.0249829 115.375447 92.4305805 30 42.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-5"
                      points="26.4756693 44.5841829 111.056156 94.0249829 105.375447 97.4305805 20 47.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-10"
                      points="16.4756693 49.5841829 101.056156 99.0249829 95.3754471 102.43058 10 52.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-11"
                      points="6.47566933 54.5841829 91.0561555 104.024983 85.3754471 107.43058 0 57.5161829"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="traffic-main"
                transform="translate(314.000000, 285.000000)">
                <mask id="mask-9" fill="white">
                  <use xlinkHref="#path-8"></use>
                </mask>
                <g id="traffic-mask"></g>
                <g id="bars-main1" mask="url(#mask-9)" fill="#FFFFFF">
                  <g transform="translate(-33.000000, -125.000000)">
                    <polygon
                      id="Path-4"
                      points="94 89.7366547 259.123251 182.495125 271.056156 179.867995 103.361993 84.4854764"></polygon>
                    <polygon
                      id="Path-4-Copy"
                      points="107.294624 83.2511783 270.417874 176.009649 282.350779 173.382519 115.656616 79"></polygon>
                    <polygon
                      id="Path-4-Copy-2"
                      points="118.294624 77.2511783 281.417874 170.009649 293.350779 167.382519 126.656616 73"></polygon>
                    <polygon
                      id="Path-4-Copy-3"
                      points="129.294624 71.2511783 292.417874 164.009649 304.350779 161.382519 137.656616 67"></polygon>
                    <polygon
                      id="Path-4-Copy-4"
                      points="141.294624 65.2511783 304.417874 158.009649 316.350779 155.382519 149.656616 61"></polygon>
                    <polygon
                      id="Path-4-Copy-5"
                      points="152.294624 59.2511783 315.417874 152.009649 327.350779 149.382519 160.656616 55"></polygon>
                    <polygon
                      id="Path-4-Copy-6"
                      points="164.294624 53.2511783 327.417874 146.009649 339.350779 143.382519 172.656616 49"></polygon>
                    <polygon
                      id="Path-4-Copy-7"
                      points="176.294624 47.2511783 339.417874 140.009649 351.350779 137.382519 184.656616 43"></polygon>
                    <polygon
                      id="Path-4-Copy-15"
                      points="0 137.736655 165.123251 231.495125 177.056156 228.867995 9.3619927 132.485476"></polygon>
                    <polygon
                      id="Path-4-Copy-14"
                      points="13.2946236 131.251178 176.417874 224.009649 188.350779 221.382519 21.6566163 127"></polygon>
                    <polygon
                      id="Path-4-Copy-13"
                      points="24.2946236 125.251178 187.417874 218.009649 199.350779 215.382519 32.6566163 121"></polygon>
                    <polygon
                      id="Path-4-Copy-12"
                      points="35.2946236 119.251178 198.417874 212.009649 210.350779 209.382519 43.6566163 115"></polygon>
                    <polygon
                      id="Path-4-Copy-11"
                      points="47.2946236 113.251178 210.417874 206.009649 222.350779 203.382519 55.6566163 109"></polygon>
                    <polygon
                      id="Path-4-Copy-10"
                      points="58.2946236 107.251178 221.417874 200.009649 233.350779 197.382519 66.6566163 103"></polygon>
                    <polygon
                      id="Path-4-Copy-9"
                      points="70.2946236 101.251178 233.417874 194.009649 245.350779 191.382519 78.6566163 97"></polygon>
                    <polygon
                      id="Path-4-Copy-8"
                      points="82.2946236 95.2511783 245.417874 188.009649 257.350779 185.382519 90.6566163 91"></polygon>
                    <polygon
                      id="Path-4-Copy-23"
                      points="187 40.7366547 353.123251 134.495125 365.056156 131.867995 196.361993 35.4854764"></polygon>
                    <polygon
                      id="Path-4-Copy-22"
                      points="200.294624 34.2511783 363.417874 127.009649 375.350779 124.382519 208.656616 30"></polygon>
                    <polygon
                      id="Path-4-Copy-21"
                      points="211.294624 28.2511783 374.417874 121.009649 386.350779 118.382519 219.656616 24"></polygon>
                    <polygon
                      id="Path-4-Copy-20"
                      points="222.294624 22.2511783 385.417874 115.009649 397.350779 112.382519 230.656616 18"></polygon>
                    <polygon
                      id="Path-4-Copy-19"
                      points="234.294624 16.2511783 397.417874 109.009649 409.350779 106.382519 242.656616 12"></polygon>
                    <polygon
                      id="Path-4-Copy-18"
                      points="245.294624 10.2511783 408.417874 103.009649 420.350779 100.382519 253.656616 6"></polygon>
                    <polygon
                      id="Path-4-Copy-17"
                      points="257.294624 4.25117832 420.417874 97.0096485 432.350779 94.3825191 265.656616 0"></polygon>
                  </g>
                </g>
                <g id="bars-main2" mask="url(#mask-9)" fill="#FFFFFF">
                  <g transform="translate(-302.000000, 15.000000)">
                    <polygon
                      id="Path-4"
                      points="94 89.7366547 259.123251 182.495125 271.056156 179.867995 103.361993 84.4854764"></polygon>
                    <polygon
                      id="Path-4-Copy"
                      points="107.294624 83.2511783 270.417874 176.009649 282.350779 173.382519 115.656616 79"></polygon>
                    <polygon
                      id="Path-4-Copy-2"
                      points="118.294624 77.2511783 281.417874 170.009649 293.350779 167.382519 126.656616 73"></polygon>
                    <polygon
                      id="Path-4-Copy-3"
                      points="129.294624 71.2511783 292.417874 164.009649 304.350779 161.382519 137.656616 67"></polygon>
                    <polygon
                      id="Path-4-Copy-4"
                      points="141.294624 65.2511783 304.417874 158.009649 316.350779 155.382519 149.656616 61"></polygon>
                    <polygon
                      id="Path-4-Copy-5"
                      points="152.294624 59.2511783 315.417874 152.009649 327.350779 149.382519 160.656616 55"></polygon>
                    <polygon
                      id="Path-4-Copy-6"
                      points="164.294624 53.2511783 327.417874 146.009649 339.350779 143.382519 172.656616 49"></polygon>
                    <polygon
                      id="Path-4-Copy-7"
                      points="176.294624 47.2511783 339.417874 140.009649 351.350779 137.382519 184.656616 43"></polygon>
                    <polygon
                      id="Path-4-Copy-15"
                      points="0 137.736655 165.123251 231.495125 177.056156 228.867995 9.3619927 132.485476"></polygon>
                    <polygon
                      id="Path-4-Copy-14"
                      points="13.2946236 131.251178 176.417874 224.009649 188.350779 221.382519 21.6566163 127"></polygon>
                    <polygon
                      id="Path-4-Copy-13"
                      points="24.2946236 125.251178 187.417874 218.009649 199.350779 215.382519 32.6566163 121"></polygon>
                    <polygon
                      id="Path-4-Copy-12"
                      points="35.2946236 119.251178 198.417874 212.009649 210.350779 209.382519 43.6566163 115"></polygon>
                    <polygon
                      id="Path-4-Copy-11"
                      points="47.2946236 113.251178 210.417874 206.009649 222.350779 203.382519 55.6566163 109"></polygon>
                    <polygon
                      id="Path-4-Copy-10"
                      points="58.2946236 107.251178 221.417874 200.009649 233.350779 197.382519 66.6566163 103"></polygon>
                    <polygon
                      id="Path-4-Copy-9"
                      points="70.2946236 101.251178 233.417874 194.009649 245.350779 191.382519 78.6566163 97"></polygon>
                    <polygon
                      id="Path-4-Copy-8"
                      points="82.2946236 95.2511783 245.417874 188.009649 257.350779 185.382519 90.6566163 91"></polygon>
                    <polygon
                      id="Path-4-Copy-23"
                      points="187 40.7366547 353.123251 134.495125 365.056156 131.867995 196.361993 35.4854764"></polygon>
                    <polygon
                      id="Path-4-Copy-22"
                      points="200.294624 34.2511783 363.417874 127.009649 375.350779 124.382519 208.656616 30"></polygon>
                    <polygon
                      id="Path-4-Copy-21"
                      points="211.294624 28.2511783 374.417874 121.009649 386.350779 118.382519 219.656616 24"></polygon>
                    <polygon
                      id="Path-4-Copy-20"
                      points="222.294624 22.2511783 385.417874 115.009649 397.350779 112.382519 230.656616 18"></polygon>
                    <polygon
                      id="Path-4-Copy-19"
                      points="234.294624 16.2511783 397.417874 109.009649 409.350779 106.382519 242.656616 12"></polygon>
                    <polygon
                      id="Path-4-Copy-18"
                      points="245.294624 10.2511783 408.417874 103.009649 420.350779 100.382519 253.656616 6"></polygon>
                    <polygon
                      id="Path-4-Copy-17"
                      points="257.294624 4.25117832 420.417874 97.0096485 432.350779 94.3825191 265.656616 0"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="traffic-slow"
                transform="translate(427.252393, 297.812079)">
                <mask id="mask-11" fill="white">
                  <use xlinkHref="#path-10"></use>
                </mask>
                <g id="Path-3"></g>
                <g id="bars-slow1" mask="url(#mask-11)" fill="#FFFFFF">
                  <g transform="translate(-91.252393, -211.812079)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="438.294624 10.2511783 601.417874 103.009649 613.350779 100.382519 446.656616 6"></polygon>
                    <polygon
                      id="Path-4-Copy-32"
                      points="450.294624 4.25117832 613.417874 97.0096485 625.350779 94.3825191 458.656616 0"></polygon>
                    <polygon
                      id="Path-4"
                      points="94 195.736655 259.123251 288.495125 271.056156 285.867995 103.361993 190.485476"></polygon>
                    <polygon
                      id="Path-4-Copy"
                      points="107.294624 189.251178 270.417874 282.009649 282.350779 279.382519 115.656616 185"></polygon>
                    <polygon
                      id="Path-4-Copy-2"
                      points="118.294624 183.251178 281.417874 276.009649 293.350779 273.382519 126.656616 179"></polygon>
                    <polygon
                      id="Path-4-Copy-3"
                      points="129.294624 177.251178 292.417874 270.009649 304.350779 267.382519 137.656616 173"></polygon>
                    <polygon
                      id="Path-4-Copy-4"
                      points="141.294624 171.251178 304.417874 264.009649 316.350779 261.382519 149.656616 167"></polygon>
                    <polygon
                      id="Path-4-Copy-5"
                      points="152.294624 165.251178 315.417874 258.009649 327.350779 255.382519 160.656616 161"></polygon>
                    <polygon
                      id="Path-4-Copy-6"
                      points="164.294624 159.251178 327.417874 252.009649 339.350779 249.382519 172.656616 155"></polygon>
                    <polygon
                      id="Path-4-Copy-7"
                      points="176.294624 153.251178 339.417874 246.009649 351.350779 243.382519 184.656616 149"></polygon>
                    <polygon
                      id="Path-4-Copy-15"
                      points="0 243.736655 165.123251 337.495125 177.056156 334.867995 9.3619927 238.485476"></polygon>
                    <polygon
                      id="Path-4-Copy-14"
                      points="13.2946236 237.251178 176.417874 330.009649 188.350779 327.382519 21.6566163 233"></polygon>
                    <polygon
                      id="Path-4-Copy-13"
                      points="24.2946236 231.251178 187.417874 324.009649 199.350779 321.382519 32.6566163 227"></polygon>
                    <polygon
                      id="Path-4-Copy-12"
                      points="35.2946236 225.251178 198.417874 318.009649 210.350779 315.382519 43.6566163 221"></polygon>
                    <polygon
                      id="Path-4-Copy-11"
                      points="47.2946236 219.251178 210.417874 312.009649 222.350779 309.382519 55.6566163 215"></polygon>
                    <polygon
                      id="Path-4-Copy-10"
                      points="58.2946236 213.251178 221.417874 306.009649 233.350779 303.382519 66.6566163 209"></polygon>
                    <polygon
                      id="Path-4-Copy-9"
                      points="70.2946236 207.251178 233.417874 300.009649 245.350779 297.382519 78.6566163 203"></polygon>
                    <polygon
                      id="Path-4-Copy-8"
                      points="82.2946236 201.251178 245.417874 294.009649 257.350779 291.382519 90.6566163 197"></polygon>
                    <polygon
                      id="Path-4-Copy-31"
                      points="279 97.7366547 445.123251 191.495125 457.056156 188.867995 288.361993 92.4854764"></polygon>
                    <polygon
                      id="Path-4-Copy-30"
                      points="292.294624 91.2511783 455.417874 184.009649 467.350779 181.382519 300.656616 87"></polygon>
                    <polygon
                      id="Path-4-Copy-29"
                      points="303.294624 85.2511783 466.417874 178.009649 478.350779 175.382519 311.656616 81"></polygon>
                    <polygon
                      id="Path-4-Copy-28"
                      points="312.294624 78.2511783 475.417874 171.009649 487.350779 168.382519 320.656616 74"></polygon>
                    <polygon
                      id="Path-4-Copy-27"
                      points="324.294624 72.2511783 487.417874 165.009649 499.350779 162.382519 332.656616 68"></polygon>
                    <polygon
                      id="Path-4-Copy-26"
                      points="335.294624 66.2511783 498.417874 159.009649 510.350779 156.382519 343.656616 62"></polygon>
                    <polygon
                      id="Path-4-Copy-25"
                      points="347.294624 60.2511783 510.417874 153.009649 522.350779 150.382519 355.656616 56"></polygon>
                    <polygon
                      id="Path-4-Copy-24"
                      points="359.294624 54.2511783 522.417874 147.009649 534.350779 144.382519 367.656616 50"></polygon>
                    <polygon
                      id="Path-4-Copy-23"
                      points="187 146.736655 353.123251 240.495125 365.056156 237.867995 196.361993 141.485476"></polygon>
                    <polygon
                      id="Path-4-Copy-22"
                      points="200.294624 140.251178 363.417874 233.009649 375.350779 230.382519 208.656616 136"></polygon>
                    <polygon
                      id="Path-4-Copy-21"
                      points="211.294624 134.251178 374.417874 227.009649 386.350779 224.382519 219.656616 130"></polygon>
                    <polygon
                      id="Path-4-Copy-20"
                      points="222.294624 128.251178 385.417874 221.009649 397.350779 218.382519 230.656616 124"></polygon>
                    <polygon
                      id="Path-4-Copy-19"
                      points="234.294624 122.251178 397.417874 215.009649 409.350779 212.382519 242.656616 118"></polygon>
                    <polygon
                      id="Path-4-Copy-18"
                      points="245.294624 116.251178 408.417874 209.009649 420.350779 206.382519 253.656616 112"></polygon>
                    <polygon
                      id="Path-4-Copy-17"
                      points="257.294624 110.251178 420.417874 203.009649 432.350779 200.382519 265.656616 106"></polygon>
                    <polygon
                      id="Path-4-Copy-16"
                      points="269.294624 104.251178 432.417874 197.009649 444.350779 194.382519 277.656616 100"></polygon>
                    <polygon
                      id="Path-4-Copy-39"
                      points="370 47.7366547 535.123251 141.495125 547.056156 138.867995 379.361993 42.4854764"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="381.294624 40.2511783 544.417874 134.009649 556.350779 131.382519 389.656616 36"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="392.294624 34.2511783 555.417874 127.009649 567.350779 124.382519 400.656616 30"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="403.294624 28.2511783 566.417874 121.009649 578.350779 118.382519 411.656616 24"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="415.294624 22.2511783 578.417874 115.009649 590.350779 112.382519 423.656616 18"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="426.294624 16.2511783 589.417874 109.009649 601.350779 106.382519 434.656616 12"></polygon>
                  </g>
                </g>
                <g id="bars-slow2" mask="url(#mask-11)" fill="#FFFFFF">
                  <g transform="translate(-553.252393, 33.187921)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="438.294624 10.2511783 601.417874 103.009649 613.350779 100.382519 446.656616 6"></polygon>
                    <polygon
                      id="Path-4-Copy-32"
                      points="450.294624 4.25117832 613.417874 97.0096485 625.350779 94.3825191 458.656616 0"></polygon>
                    <polygon
                      id="Path-4"
                      points="94 195.736655 259.123251 288.495125 271.056156 285.867995 103.361993 190.485476"></polygon>
                    <polygon
                      id="Path-4-Copy"
                      points="107.294624 189.251178 270.417874 282.009649 282.350779 279.382519 115.656616 185"></polygon>
                    <polygon
                      id="Path-4-Copy-2"
                      points="118.294624 183.251178 281.417874 276.009649 293.350779 273.382519 126.656616 179"></polygon>
                    <polygon
                      id="Path-4-Copy-3"
                      points="129.294624 177.251178 292.417874 270.009649 304.350779 267.382519 137.656616 173"></polygon>
                    <polygon
                      id="Path-4-Copy-4"
                      points="141.294624 171.251178 304.417874 264.009649 316.350779 261.382519 149.656616 167"></polygon>
                    <polygon
                      id="Path-4-Copy-5"
                      points="152.294624 165.251178 315.417874 258.009649 327.350779 255.382519 160.656616 161"></polygon>
                    <polygon
                      id="Path-4-Copy-6"
                      points="164.294624 159.251178 327.417874 252.009649 339.350779 249.382519 172.656616 155"></polygon>
                    <polygon
                      id="Path-4-Copy-7"
                      points="176.294624 153.251178 339.417874 246.009649 351.350779 243.382519 184.656616 149"></polygon>
                    <polygon
                      id="Path-4-Copy-15"
                      points="0 243.736655 165.123251 337.495125 177.056156 334.867995 9.3619927 238.485476"></polygon>
                    <polygon
                      id="Path-4-Copy-14"
                      points="13.2946236 237.251178 176.417874 330.009649 188.350779 327.382519 21.6566163 233"></polygon>
                    <polygon
                      id="Path-4-Copy-13"
                      points="24.2946236 231.251178 187.417874 324.009649 199.350779 321.382519 32.6566163 227"></polygon>
                    <polygon
                      id="Path-4-Copy-12"
                      points="35.2946236 225.251178 198.417874 318.009649 210.350779 315.382519 43.6566163 221"></polygon>
                    <polygon
                      id="Path-4-Copy-11"
                      points="47.2946236 219.251178 210.417874 312.009649 222.350779 309.382519 55.6566163 215"></polygon>
                    <polygon
                      id="Path-4-Copy-10"
                      points="58.2946236 213.251178 221.417874 306.009649 233.350779 303.382519 66.6566163 209"></polygon>
                    <polygon
                      id="Path-4-Copy-9"
                      points="70.2946236 207.251178 233.417874 300.009649 245.350779 297.382519 78.6566163 203"></polygon>
                    <polygon
                      id="Path-4-Copy-8"
                      points="82.2946236 201.251178 245.417874 294.009649 257.350779 291.382519 90.6566163 197"></polygon>
                    <polygon
                      id="Path-4-Copy-31"
                      points="279 97.7366547 445.123251 191.495125 457.056156 188.867995 288.361993 92.4854764"></polygon>
                    <polygon
                      id="Path-4-Copy-30"
                      points="292.294624 91.2511783 455.417874 184.009649 467.350779 181.382519 300.656616 87"></polygon>
                    <polygon
                      id="Path-4-Copy-29"
                      points="303.294624 85.2511783 466.417874 178.009649 478.350779 175.382519 311.656616 81"></polygon>
                    <polygon
                      id="Path-4-Copy-28"
                      points="312.294624 78.2511783 475.417874 171.009649 487.350779 168.382519 320.656616 74"></polygon>
                    <polygon
                      id="Path-4-Copy-27"
                      points="324.294624 72.2511783 487.417874 165.009649 499.350779 162.382519 332.656616 68"></polygon>
                    <polygon
                      id="Path-4-Copy-26"
                      points="335.294624 66.2511783 498.417874 159.009649 510.350779 156.382519 343.656616 62"></polygon>
                    <polygon
                      id="Path-4-Copy-25"
                      points="347.294624 60.2511783 510.417874 153.009649 522.350779 150.382519 355.656616 56"></polygon>
                    <polygon
                      id="Path-4-Copy-24"
                      points="359.294624 54.2511783 522.417874 147.009649 534.350779 144.382519 367.656616 50"></polygon>
                    <polygon
                      id="Path-4-Copy-23"
                      points="187 146.736655 353.123251 240.495125 365.056156 237.867995 196.361993 141.485476"></polygon>
                    <polygon
                      id="Path-4-Copy-22"
                      points="200.294624 140.251178 363.417874 233.009649 375.350779 230.382519 208.656616 136"></polygon>
                    <polygon
                      id="Path-4-Copy-21"
                      points="211.294624 134.251178 374.417874 227.009649 386.350779 224.382519 219.656616 130"></polygon>
                    <polygon
                      id="Path-4-Copy-20"
                      points="222.294624 128.251178 385.417874 221.009649 397.350779 218.382519 230.656616 124"></polygon>
                    <polygon
                      id="Path-4-Copy-19"
                      points="234.294624 122.251178 397.417874 215.009649 409.350779 212.382519 242.656616 118"></polygon>
                    <polygon
                      id="Path-4-Copy-18"
                      points="245.294624 116.251178 408.417874 209.009649 420.350779 206.382519 253.656616 112"></polygon>
                    <polygon
                      id="Path-4-Copy-17"
                      points="257.294624 110.251178 420.417874 203.009649 432.350779 200.382519 265.656616 106"></polygon>
                    <polygon
                      id="Path-4-Copy-16"
                      points="269.294624 104.251178 432.417874 197.009649 444.350779 194.382519 277.656616 100"></polygon>
                    <polygon
                      id="Path-4-Copy-39"
                      points="370 47.7366547 535.123251 141.495125 547.056156 138.867995 379.361993 42.4854764"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="381.294624 40.2511783 544.417874 134.009649 556.350779 131.382519 389.656616 36"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="392.294624 34.2511783 555.417874 127.009649 567.350779 124.382519 400.656616 30"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="403.294624 28.2511783 566.417874 121.009649 578.350779 118.382519 411.656616 24"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="415.294624 22.2511783 578.417874 115.009649 590.350779 112.382519 423.656616 18"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="426.294624 16.2511783 589.417874 109.009649 601.350779 106.382519 434.656616 12"></polygon>
                  </g>
                </g>
              </g>
              <g id="input1" transform="translate(169.000000, 358.000000)">
                <mask id="mask-13" fill="white">
                  <use xlinkHref="#path-12"></use>
                </mask>
                <g id="input1-mask"></g>
                <g id="bars-input1-1" mask="url(#mask-13)" fill="#FFFFFF">
                  <g transform="translate(-15.000000, -8.000000)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 4.25117832 109.417874 33.3267215 121.350779 30.699592 65.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 34.2511783 52.4178742 64.3267215 64.3507792 60.699592 8.65661632 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 28.2511783 63.4178742 57.3267215 75.3507792 54.699592 19.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 22.2511783 74.4178742 51.3267215 86.3507792 48.699592 30.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 16.2511783 86.4178742 45.3267215 98.3507792 42.699592 42.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 10.2511783 97.4178742 39.3267215 109.350779 36.699592 53.6566163 6"></polygon>
                  </g>
                </g>
                <g id="bars-input1-2" mask="url(#mask-13)" fill="#FFFFFF">
                  <g transform="translate(-84.000000, 28.000000)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 4.25117832 109.417874 33.3267215 121.350779 30.699592 65.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 34.2511783 52.4178742 64.3267215 64.3507792 60.699592 8.65661632 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 28.2511783 63.4178742 57.3267215 75.3507792 54.699592 19.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 22.2511783 74.4178742 51.3267215 86.3507792 48.699592 30.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 16.2511783 86.4178742 45.3267215 98.3507792 42.699592 42.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 10.2511783 97.4178742 39.3267215 109.350779 36.699592 53.6566163 6"></polygon>
                  </g>
                </g>
              </g>
              <path
                d="M613.147825,0.603577707 C619.491914,0.603577707 625.839243,2.00086367 630.680776,4.81569876 L630.680776,4.81569876 L1020.85511,226.980316 C1025.51255,229.688641 1027.91311,233.213934 1027.91311,236.779058 C1027.91311,240.344131 1025.51258,243.868701 1020.85815,246.573982 L1020.85815,246.573982 L433.696554,583.459064 C424.189765,588.988117 417.18968,591.256761 410.31156,590.841736 C402.656246,590.379816 395.1681,586.61404 384.582721,580.457696 L384.582721,580.457696 L7.65947077,363.518899 C3.00339826,360.811916 0.603577707,357.287314 0.603577707,353.722246 C0.603577707,350.157127 3.00342782,346.631802 7.65765977,343.924579 L7.65765977,343.924579 L595.619591,4.81298486 C600.456408,2.00086367 606.803737,0.603577707 613.147825,0.603577707 Z"
                id="--copy"
                strokeOpacity="0"
                stroke="#000000"
                strokeWidth="1.20715541"
                opacity="0.300000012"></path>
              <g id="input2" transform="translate(281.535549, 423.887821)">
                <mask id="mask-15" fill="white">
                  <use xlinkHref="#path-14"></use>
                </mask>
                <g id="input2-mask"></g>
                <g id="bars-input2-1" mask="url(#mask-15)" fill="#FFFFFF">
                  <g transform="translate(-16.535549, -10.887821)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 10.2511783 109.417874 39.3267215 121.350779 36.699592 65.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-32"
                      points="69.2946236 4.25117832 117.290733 31.6079465 129.223638 28.9808171 77.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 40.2511783 52.4178742 70.3267215 64.3507792 66.699592 8.65661632 36"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 34.2511783 63.4178742 63.3267215 75.3507792 60.699592 19.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 28.2511783 74.4178742 57.3267215 86.3507792 54.699592 30.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 22.2511783 86.4178742 51.3267215 98.3507792 48.699592 42.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 16.2511783 97.4178742 45.3267215 109.350779 42.699592 53.6566163 12"></polygon>
                  </g>
                </g>
                <g id="bars-input2-2" mask="url(#mask-15)" fill="#FFFFFF">
                  <g transform="translate(-97.535549, 31.112179)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 10.2511783 109.417874 39.3267215 121.350779 36.699592 65.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-32"
                      points="69.2946236 4.25117832 117.290733 31.6079465 129.223638 28.9808171 77.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 40.2511783 52.4178742 70.3267215 64.3507792 66.699592 8.65661632 36"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 34.2511783 63.4178742 63.3267215 75.3507792 60.699592 19.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 28.2511783 74.4178742 57.3267215 86.3507792 54.699592 30.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 22.2511783 86.4178742 51.3267215 98.3507792 48.699592 42.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 16.2511783 97.4178742 45.3267215 109.350779 42.699592 53.6566163 12"></polygon>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <img
        id="img"
        src="/img/solutions/eiam-hero.png"
        alt="Edge Infrastructure & App Management"
      />
    </div>
  )
}

export default EiamHeroAnim
